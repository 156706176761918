import request from '@/util/request'

export const combinationCreate = (data) => {
  return request({
    'url': '/api/v1/marketing/combination/create',
    'method': 'POST',
    data
  })
}

export const combinationDetail = (params) => {
  return request({
    'url': '/api/v1/marketing/combination/detail',
    'method': 'GET',
    params
  })
}

export const combinationEdit = (data) => {
  return request({
    'url': '/api/v1/marketing/combination/edit',
    'method': 'POST',
    data
  })
}

export const combinationDelete = (params) => {
  return request({
    'url': '/api/v1/marketing/combination/delete',
    'method': 'GET',
    params
  })
}

export const combinationUnion = (params) => {
  return request({
    'url': '/api/v1/marketing/combination/union',
    'method': 'GET',
    params
  })
}

export const combinationList = (params) => {
  return request({
    'url': '/api/v1/marketing/combination/list',
    'method': 'GET',
    params
  })
}
