<template>
  <div class="delivery-allocated-users">
    <div class="delivery-allocated-device-data-list">
      <el-table :data="drinks"
                ref="multipleTable"
                max-height="420px"
                @select="listChange"
                @select-all="handleBatch">
        <el-table-column type="selection">
        </el-table-column>
        <el-table-column label="饮品编号" align="center" prop="drinkUuid"></el-table-column>
        <el-table-column label="饮品信息" align="center" min-width="160">
          <template slot-scope="scope">
            <div class="drink-item-info">
              <span>{{scope.row.name}}    /     {{scope.row.engName}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="小杯价格(元)" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.price /100 | F1000}}</span>
          </template>
        </el-table-column>
        <el-table-column label="中杯价格(元)" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.middlePrice/100 | F1000}}</span>
          </template>
        </el-table-column>
        <el-table-column label="大杯价格(元)" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.bigPrice/100 | F1000}}</span>
          </template>
        </el-table-column>

      </el-table>
    </div>
    <el-pagination
      @current-change="handleCurrentChange"
      class="pagination"
      :current-page="pagination.curPage"
      :page-size="pagination.limit"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>
  </div>

</template>

<script>
  import {drinkGroupUnion, drinkGroupList} from '@/api/equipment/drink'

  export default {
    name: '',
    props: {},
    components: {},
    computed: {},
    mounted: function () {
    },
    data: function () {
      return {
        drinks: [],
        loading: false,
        pagination: {
          start: 0,
          curPage: 1,
          limit: 10,
          total: 0,
        },
        checkDrink: []
      }
    },

    methods: {
      handleCurrentChange(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        drinkGroupList(this.pagination).then(res => {
          this.drinks = res.data || []
          this.$nextTick(function () {
            this.judoCheck(this.drinks)
          })
        })
      },

      judoCheck(drinks) {
        for (let j = 0; j < drinks.length; j++) {
          this.$set(drinks[j], `checked`, false)
        }

        for (let j = 0; j < drinks.length; j++) {
          for (let i = 0; i < this.checkDrink.length; i++) {
            if (drinks[j].drinkUuid === this.checkDrink[i].drinkUuid) {
              this.$refs.multipleTable.toggleRowSelection(drinks[j])
              break
            }
          }
        }
        return drinks
      },

      handleBatch(value) {
        for (let j = 0; j < this.drinks.length; j++) {
          for (let i = 0; i < this.checkDrink.length; i++) {
            if (this.drinks[j].drinkUuid === this.checkDrink[i].drinkUuid) {
              this.checkDrink.splice(i, 1)
            }
          }
        }

        if (value.length !== 0) {
          for (let j = 0; j < value.length; j++) {
            this.checkDrink.push(value[j])
          }
        }
      },


      listChange(selection) {
        this.handleBatch(selection)
      },


      loadDrink(drinks) {
        let vm = this
        vm.checkDrink = []
        drinks = (drinks || [])

        drinks.forEach(item => {
            vm.checkDrink.push({
            drinkUuid: item.drinkUuid, name: item.name, engName: item.engName,
            price: item.price, middlePrice: item.middlePrice, bigPrice: item.bigPrice
          })
        })
        drinkGroupUnion(this.pagination).then(res => {
          vm.drinks = res.data.list || []
          vm.pagination.total = parseInt(res.data.total)
            this.$nextTick(function () {
              vm.judoCheck(vm.drinks)
            })
        })
      },


      getCheckDrink() {
        return this.checkDrink
      },
    },
  }
</script>

<style lang="scss">
  .delivery-allocated-users {
    padding: $padding;
    display: flex;
    flex-direction: column;

    .delivery-allocated-device-title {
      .delivery-allocated-device-input-filter {
        input {
          border: none;
          border-bottom: 1px solid;
          border-radius: 0;
        }
      }

      .delivery-allocated-device-status-filter {
        max-width: 124px;

        .el-input--suffix .el-input__inner {
          padding-right: 0;
        }
      }

      .more {
        font-size: .6rem;
        height: 28px !important;
        line-height: 28px;
        display: table-cell;
        vertical-align: middle;
        text-align: center;

        span {
          cursor: pointer;
        }
      }
    }

    .delivery-allocated-device-data-list {
      margin-top: $padding;
      flex: 1;

      .delivery-allocated-device-status {
        .delivery-allocated-device-info-img {
        }

        .delivery-allocated-device-info-detail {

          p {
            margin-bottom: $padding;
          }

          p:first-child {
            font-size: .8rem;
          }
        }
      }

      .delivery-allocated-device-address {
        p {
          margin-bottom: $padding;
        }
      }

      .el-table {
        thead {
          tr {
            th {
              border-bottom: 1px solid;
            }
          }
        }

      }

      .el-table--group::after, .el-table--border::after, .el-table::before {
        background-color: transparent;
      }

      .el-table__row:hover {
      }

    }

    .pagination {
      margin: $padding;
    }
  }

</style>
