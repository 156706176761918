<template>
  <transition name="fade-transform" mode="out-in">
    <div class="ppy-dialog" :class="{showTitle: showTitle}" v-if="visible" @click.capture.self="close(false)">

      <div class="dialog-wrapper"
           :style="{width: `${width}`,height: `${height}`}"
           v-show="visible"
      >
        <div class="close-button" @click="close(true)">
          <span class="iconfont icon-guanbi"></span>
        </div>
        <div class="title clear-fix">
          <p class="title-name" v-if="title" v-html="title"></p>
          <div class="title-right">
            <slot name="title"></slot>
          </div>
        </div>
        <div class="content">
          <div class="content-wrapper">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>


  export default {
    props: {
      visible: {
        type: Boolean,
        default: false
      },

      outerClose: {
        type: Boolean,
        default: true
      },
      width: {
        type: String,
        default: '30rem'
      },
      height: {
        type: String,
        default: '15rem'
      },
      title: {
        type: String,
        default: ''
      }
    }, components: {},
    data() {
      return {
        showTitle: this.title ? true : false
      }
    },
    methods: {
      close(isForce = false) {
        if (!isForce && !this.outerClose) {
          return
        }
        this.$emit('beforeClose');
        this.$emit('update:visible', false);
      }
    },
    mounted() {
    }
  }
</script>
<style lang="scss" scoped>
  .ppy-dialog {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, .3);
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      /*width: 100%;*/
      /*height: 100%;*/
      overflow-y: scroll;
      filter: blur(10px);
    }

    &.showTitle {
      h2 {
        display: block;
      }

      .content {
        top: 64px;
      }
    }

    .title {
      border-bottom: 1px solid;
      @include set_border_color($--border-color, $--border-color-dark);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 99;

      .title-name {
        display: inline-block;
        font-size: .9rem;
        font-weight: 500;
        @include set_font_color($--font-color, $--font-color-dark);
        height: $_header_sec_height;
        line-height: $_header_sec_height;
        margin-right: $padding;
      }

      .title-right {
        flex: 1;
        text-align: right;
      }
    }

    .dialog-wrapper {
      box-sizing: border-box;
      @include horizontal-vertical;
      padding: 0 $padding;
      @include set_bg_color($background-color-light, $background-color-dark);
      @include shadow(0px, 0px, 10px, rgba(0, 0, 0, .375));
      @include rounded(8px);
      @include transition(all)
    }

    .content {
      width: 100%;
      position: absolute;
      top: 30px;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;

      .content-wrapper {
        padding: 0 15px;
        /*overflow: hidden;*/
      }
    }

    .close-button {
      position: absolute;
      top: -50px;
      @include rounded(50%);
      border: 2px solid;
      @include set_border_color($--border-color, $--font-color-dark);
      @include set_font_color($--border-color, $--font-color-dark);
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      right: 10px;
      cursor: pointer;

      &:after {
        content: '';
        height: 30px;
        width: 2px;
        @include set_bg_color($background-color-light, $background-color-dark);
        position: absolute;
        left: 50%;
        bottom: -31px;
        transform: translateX(-0.5px);
      }

      .iconfont {
        font-size: 24px;
      }
    }

  }
</style>
