/**
 * Created by fworld on 2019/9/26.
 */

import request from '@/util/request'

export const drinkDeviceUnion = (params) => {
  return request({
    url: '/api/v1/equipment/drink/device/union',
    method: 'get',
    params
  })
}


export const drinkDeviceList = (params) => {
  return request({
    url: '/api/v1/equipment/drink/device/list',
    method: 'get',
    params
  })
}

export const drinkDeviceUpdate = (data) => {
  return request({
    url: '/api/v1/equipment/drink/device/update',
    method: 'post',
    data
  })
}


export const drinkFullPull = (params) => {
  return request({
    url: '/api/v1/equipment/drink/fullPull',
    method: 'get',
    params
  })
}


export const drinkSyncDevice = (params) => {
  return request({
    'url': '/api/v1/equipment/drink/syncDevice',
    'method': 'GET',
    params
  })
}

export const drinkGroupUnion = (params) => {
  return request({
    'url': '/api/v1/equipment/drink/group/union',
    'method': 'GET',
    params
  })
}

export const drinkGroupList = (params) => {
  return request({
    'url': '/api/v1/equipment/drink/group/list',
    'method': 'GET',
    params
  })
}

export const drinkSyncUnion = (params) => {
  return request({
    'url': '/api/v1/equipment/drink/sync/union',
    'method': 'GET',
    params
  })
}

export const drinkSyncList = (params) => {
  return request({
    'url': '/api/v1/equipment/drink/sync/list',
    'method': 'GET',
    params
  })
}

export const drinkSyncParam = (params) => {
  return request({
    'url': '/api/v1/equipment/drink/sync/param',
    'method': 'GET',
    params
  })
}

export const drinkSyncDelete = (params) => {
  return request({
    'url': '/api/v1/equipment/drink/sync/delete',
    'method': 'GET',
    params
  })
}

export const drinkSyncDeviceUnion = (params) => {
  return request({
    url: '/api/v1/equipment/drink/sync/device/union',
    method: 'get',
    params
  })
}

export const drinkSyncDeviceList = (params) => {
  return request({
    url: '/api/v1/equipment/drink/sync/device/list',
    method: 'get',
    params
  })
}

export const drinkSyncProductUuid = (params) => {
  return request({
    url: '/api/v1/equipment/drink/sync/productUuid',
    method: 'get',
    params
  })
}

export const drinkSyncPushDevice = (data) => {
  return request({
    url: '/api/v1/equipment/drink/sync/pushDevice',
    method: 'post',
    data
  })
}



